
/* 
 * A util function to create an object with the same keys and values
 */
const createMirroredObject = propertyNames => {
    let mirror = {};
    for (let prop of propertyNames) {
        mirror[prop] = prop;
    }

    return mirror;
}

// Create an object with constant values
/*
{
    IDLE: 'IDLE',
    PENDING: 'PENDING',
    ... and so on
}

*/
export const API_STATUS = createMirroredObject([
    'IDLE',
    'PENDING',
    'SUCCESS',
    'ERROR'
])