import Vue from 'vue';
import algoliasearch from 'algoliasearch';

export const searchClient = algoliasearch(
  '05XAX70WF8',
  'c75fc1942a6dfd75a8c4692dae60c549'
);

const initialState = {
  searchState: {
    query: '',
  },
};

const state = Vue.observable(initialState);

export const searchStateComputed = () => ({
  searchState: {
    get() {
      return state.searchState;
    },
  },
});

export const setSearchState = (value) => {
  state.searchState = value;
};

// export const syncIndex = searchClient.initIndex('syncs_2');
export const syncIndex = searchClient.initIndex('syncs-prod');
export const songsIndex = searchClient.initIndex('songs-prod2');
export const versionsIndex = searchClient.initIndex('versions-prod');
// export const songsIndex = searchClient.initIndex('sample-data');
