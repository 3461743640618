/**
 * Resize service will update window width and height
 */
import Vue from 'vue';
import { throttle } from 'lodash-es';

const initialState = {
  width: 0,
  height: 0,
};

const state = Vue.observable(initialState);

const updateSize = throttle(() => {
  state.width = window.innerWidth;
  state.height = window.innerHeight;
  console.log('on resize', state);
}, 250);

updateSize();

window.addEventListener('resize', updateSize, {
  passive: true,
});

export const resizeComputed = {
  screen() {
    return state;
  },
};

export default state;
