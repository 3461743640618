<template>
  <div id="app">
    <AppOverlay />
    <component :is="layoutComponent">
      <div>
        <div id="content">
          <div class="container">
            <template v-if="!isUserInitialised">
              <p>Loading...</p>
            </template>
            <template v-else-if="user">
              <app-menu></app-menu>
              <ais-instant-search
                index-name="songs-prod2"
                v-bind:search-state="searchState"
                v-bind:search-client="$options.searchClient"
                onSearchStateChange="onSearchStateChange"
              >
                <router-view></router-view>
              </ais-instant-search>
            </template>
            <template v-else>
              <Login />
            </template>
          </div>
        </div>
      </div>
      <!-- End Vue App -->

      <footer id="footer">
        <div class="container">
          Copyright 2021. You're welcome!
          <img src="@/assets/img/emoticon.png" alt="emo" /><br />
          <div style="margin-top: 5px">
            <small><b>ver 0.13</b></small>
          </div>
        </div>
      </footer>
    </component>
  </div>
</template>

<script>
import { searchClient, searchStateComputed } from "./services/algoliaService";
import { firebase } from "@/config/firebase.config";
import { fetchUser } from "./api/userApi";
import { setUser, userComputed } from "./services/userService";
import AppMenu from "./components/menu/Menu";
import DefaultLayout from "./layouts/DefaultLayout";
import AuthLayout from "./layouts/AuthLayout";
import Login from "./views/Login";
import AppOverlay from "@/components/common/AppOverlay";
export default {
  components: {
    AppMenu,
    AppOverlay,
    DefaultLayout,
    AuthLayout,
    Login,
  },
  data() {
    return {
      isUserInitialised: false,
    };
  },
  created: function () {
    this.$options.searchClient = searchClient;

    firebase.auth().onAuthStateChanged(async (user) => {
      console.log("auth user?", user);
      try {
        if (user) {
          const {
            uid,
            phoneNumber,
            photoURL,
            emailVerified,
            displayName,
            email,
            isAnonymous,
          } = user;

          let userData = {
            uid,
            phoneNumber,
            photoURL,
            emailVerified,
            displayName,
            email,
            isAnonymous,
          };
          console.log("before fetch");
          const response = await fetchUser(uid);
          console.log("after fetch", response);
          console.log("user data", response);
          if (response.exists) {
            userData = {
              ...userData,
              ...response.data(),
            };
          }

          setUser(userData);
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error(error);
        setUser(null);
      } finally {
        this.isUserInitialised = true;
      }
    });
  },
  computed: {
    ...searchStateComputed(),
    ...userComputed,
    layoutComponent() {
      return this.isUserInitialised && this.user
        ? "default-layout"
        : "auth-layout";
    },
  },
  methods: {},
};
</script>

<style lang="scss">
#app {
  height: 100%;
  @include mobile {
    padding: 0 1.5rem;
  }
}

#footer {
  display: none;
  @include tablet {
    display: block;
  }
}

.container {
  max-width: 1070px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 50px;

  @media (max-width: 1070px) {
    .container {
      padding: 0 30px;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }
  }

  @include tablet-landscape-only {
    max-width: 50rem;
    margin: 0 auto;
  }
}
</style>
