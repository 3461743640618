import { db, firebase } from '@/config/firebase.config.js';

const usersCollection = db.collection('users');

export const updateUser = async (data) => {
  let { uid } = data;
  const payload = {
    ...data,
    updated_at: firebase.firestore.Timestamp.now(),
  };
  await usersCollection.doc(uid).set(payload, {
    merge: true,
  });

  return payload;
};

export const fetchUser = (id) => usersCollection.doc(id).get();
