import { firebase } from '@/config/firebase.config.js';

export const login = (email, password) => {
  return firebase.auth().signInWithEmailAndPassword(email, password);
};

export const register = (email, password) => {
  return firebase.auth().createUserWithEmailAndPassword(email, password);
};

export const logout = () => firebase.auth().signOut();

export const updatePassword = (password) =>
  firebase.auth().currentUser.updatePassword(password);

export const reauthenticateWithPassword = (email, password) => {
  const credential = firebase.auth.EmailAuthProvider.credential(
    email,
    password
  );
  return firebase.auth().currentUser.reauthenticateWithCredential(credential);
};
